import React from 'react';
import { Link } from 'gatsby';
import { HeaderSimple } from '../components/headers';
import LandingImageOne from '../components/images/LandingImageTwo';
import Layout from '../layout';

import LandingImageThree from '../components/images/LandingImageThree';
import SEO from '../components/SEO';
import LogoReel from '../components/images/logoreel';
import BgVideoTestimonial from '../components/bgVideoTestimonial';
import ImageQuote from '../components/images/ImageQuote';
import ItImage from '../components/images/ItImage';
import ItImageTwo from '../components/images/ItImageTwo';
import Clients from '../components/clients';
import Quote from '../components/Quotes';
import BookDiscoveryCall from '../components/BookDiscoveryCall';

const It = () => (
  <Layout>
    <SEO />
    <div className="bg-white py-5" />
    <div className="container-fluid pt-5 mx-auto">
      <div className="container text-center text-md-left mx-auto">
        <span className="p-3 bg-themeSecondary m-3" />
        <h2 className=" display-2 align-self-center font-weight-bold  text-neutralDark">
          When everything is going digital, we make the move easier
        </h2>
        <h2 className=" intro align-self-center font-weight-light  text-neutralTertiary">
          You already understand that you need visibility and traffic to convert leads. We look at your digital
          footprint and analyze what tools and strategies suited for you.
        </h2>
        <br />
        <p className="lead">
          <span className="font-weight-bold">
            We optimize for lead generation. From basic SEO to PPC campaigns and Advertising, to installing marketing
            technologies. We take care of the heavy lifting and let you do you.
          </span>
          <br />
          <br />
          With our Google my Business Optimization, we make search engines trust your business. Google my business
          proves that you're a legitimate local resource that provides value. We understand that you need to build a
          strong, lasting reputation by collecting and showing Google reviews next to your listing to automatically
          increase conversions.
          <br />
          <br />
          With our On-page Optimization services, we update and optimize on-page content for search. Targeting
          highest-traffic webpages, refining for leads and conversion. We focus and compare keywords with competitor
          analysis, including Fine-tuning your content strategy for the greatest returns
        </p>
      </div>

      <div className="row p-0 bg-white align-items-start m-0">
        <div className="col-md-7  image-link-hover p-0">
          <div className="card case-study-height-auto case-study border-0 rounded-0 text-white">
            <ItImage />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row h-100 p-2 p-md-5">
          <p className="text-center col-md-12 text-muted text-uppercase font-weight-bold mt-3 inline-block px-3 py-5">
            <span className="p-3 bg-themeSecondary m-3" />
            How we help
          </p>
          <div className="col-md-6 align-self-center">
            <ul className="list-group-flush pl-2">
              <li className="list-group-item case-text text-themePrimary border-0">Google My Business</li>
              <li className="list-group-item case-text text-themePrimary border-0">On-page Optimization</li>
              <li className="list-group-item case-text text-themePrimary border-0">Local SEO</li>
            </ul>
          </div>
          <div className="col-md-6 align-self-center">
            <ul className="list-group-flush pl-2">
              <li className="list-group-item case-text text-themePrimary border-0">Campaign Planning</li>
              <li className="list-group-item case-text text-themePrimary border-0">Facebook ads</li>
              <li className="list-group-item case-text text-themePrimary border-0">Instagram ads</li>
            </ul>
          </div>
        </div>
      </div>
      <Clients clientImage={<ItImage />} text=" We Make Digital and IT Simple" />
      <Quote
        video={<BgVideoTestimonial />}
        name="Andile Siziba"
        job="Director of Neurophysiology | MediServices Healthcare"
        quoteOne="Since approaching Onai Intelligence to build our website and re-brand our business, we have since seen an
                increase in our digital ROI."
        quoteTwo=" Onai has since extended our website to include other processes as well as their other Digital, IT and
                SEO services. I would highly recommend Onai to any small business"
      />
      <BookDiscoveryCall />
    </div>
  </Layout>
);

export default It;
